import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const formSlice = createSlice({
  name: 'form',
  initialState: initialState,
  reducers: {
    updateForm: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearForm: () => {
      return initialState;
    }
  }
});

export const discDataSlice = createSlice({
  name: 'discData',
  initialState: initialState,
  reducers: {
    updateDiscData: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearDiscData: () => {
      return initialState;
    }
  }
});

export const { updateForm, clearForm } = formSlice.actions;
export const selectForm = (state) => state.form;
export const selectDiscData = (state) => state.discData;

export default formSlice.reducer;
