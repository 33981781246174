import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: initialState,
  reducers: {
    updateVehicle: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearVehicle: () => {
      return initialState;
    }
  }
});

export const { updateVehicle, clearVehicle } = vehicleSlice.actions;
export const selectVehicle = (state) => state.vehicle;

export default vehicleSlice.reducer;
