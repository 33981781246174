import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Row, Spin } from 'antd';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import dayjs from 'dayjs';
import { persistor, store } from './store';

const TheLayout = React.lazy(() => import('../containers/TheLayout'));

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <React.Suspense
            fallback={
              <Row justify='center'>
                <Spin size='large' />
              </Row>
            }
          >
            <Switch>
              <Route path='/' name='Home' render={(props) => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
