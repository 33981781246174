import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const driverSlice = createSlice({
  name: 'driver',
  initialState: initialState,
  reducers: {
    updateDriver: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearDriver: () => {
      return initialState;
    }
  }
});

export const { updateDriver, clearDriver } = driverSlice.actions;
export const selectDriver = (state) => state.driver;

export default driverSlice.reducer;
